import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import AudioPlayer from "../../../../../components/audio-player"
import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Grid from "../../../../../components/grid"
import Heading from "../../../../../components/heading"
import Note from "../../../../../components/note"
import PaperStack from "../../../../../components/paper-stack"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import TaskSidebar from "../../../../../components/task-sidebar"
import Text from "../../../../../components/text"

import PoemHaelfteDesLebens from "../../../../../content/kurse/haelfte-des-lebens/01-zwei-haelften/poem-haelfte-des-lebens"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/01-zwei-haelften/zusammenfassung" />
      }
    >
      <Seo title="Das Gedicht hören" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h1" level={2}>
              Das Gedicht hören
            </Heading>
          </Stack>
          <Paragraph>
            Wie klingt das nun gesprochen? Der Sprechkünstler Christian Reiner
            und der Schauspieler Robert Arnold haben das Gedicht für uns
            eingelesen. Hör dir ihre Interpretation des Gedichts hier einmal an.
          </Paragraph>
          <Grid columns={[1, 2]} space={6}>
            <AudioPlayer
              title={
                <Text sans>
                  Christian Reiner liest{" "}
                  <Text bold as="span">
                    ›Hälfte des Lebens‹
                  </Text>
                </Text>
              }
              src={`kurse/haelfte-des-lebens-reiner`}
            />
            <AudioPlayer
              title={
                <Text sans>
                  Robert Arnold liest{" "}
                  <Text bold as="span">
                    ›Hälfte des Lebens‹
                  </Text>
                </Text>
              }
              src={`kurse/haelfte-des-lebens-arnold`}
            />
          </Grid>
          <TaskSidebar
            main={
              <PaperStack>
                <PoemHaelfteDesLebens showTitle />
              </PaperStack>
            }
            sidebar={
              <Note variant="task">
                Wie würdest du das Gedicht vortragen? Versuche es einmal und
                probiere dabei verschiedene Varianten aus: Du kannst das Gedicht
                im Sitzen, im Stehen, im Gehen oder in der Hocke einlesen. Du
                kannst es laut oder leise, stimmungsgeladen oder zurückhaltend
                sprechen. Dabei kannst du auch ausprobieren, beim Wechsel von
                der ersten in die zweite Strophe einen anderen Ton anzuschlagen.
              </Note>
            }
          />
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
